import {
  ExperienceModel,
  experienceService,
} from '@/services/modules/experience'
import { currentLanguage } from '@/utils/language'
import { computed } from '@vue/composition-api'
import { useService } from './service'

export function useExperiences(model?: ExperienceModel) {
  const request = computed<ExperienceModel>(() => ({
    languageId: currentLanguage.value.guid,
    ...model,
  }))

  return useService(request, () => experienceService.get(request.value))
}
