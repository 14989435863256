






































































import SiteSection from '@/components/SiteSection.vue'
import ExperienceCardSkeleton from '@/components/ExperienceCardSkeleton.vue'
import ExperienceCard from '@/components/ExperienceCard.vue'
import { useExperiences } from '@/composition/experiences'
import { computed, defineComponent, ref } from '@vue/composition-api'
import { CategoryExperienceViewModel } from '@/services/modules/experience'
import i18n from '@/setup/i18n'

export default defineComponent({
  name: 'Experiences',

  components: {
    SiteSection,
    ExperienceCardSkeleton,
    ExperienceCard,
  },

  setup() {
    const rowsPerPageExperience = 8

    const { data: experienceList, isLoading: isLoadingExperienceList } =
      useExperiences()

    const categoryFilterBy = ref<CategoryExperienceViewModel | null>(null)
    const categoryFilterByOptions = computed(() => {
      const records: Record<string, CategoryExperienceViewModel> = {}
      for (const experience of experienceList.value) {
        if (records[experience.categoryViewModel.categoryId]) continue
        records[experience.categoryViewModel.categoryId] =
          experience.categoryViewModel
      }
      return Object.values(records)
    })
    const categoryFilterByPlaceholder = computed(() =>
      i18n.t('filterByPlaceholder')
    )

    const filteredExperienceList = computed(() => {
      const filterBy = categoryFilterBy.value
      if (!filterBy) return experienceList.value
      return experienceList.value.filter(
        (experience) =>
          experience.categoryViewModel.categoryId === filterBy.categoryId
      )
    })

    return {
      rowsPerPageExperience,
      experienceList,
      isLoadingExperienceList,
      categoryFilterBy,
      categoryFilterByOptions,
      categoryFilterByPlaceholder,
      filteredExperienceList,
    }
  },
})
