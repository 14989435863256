











































































import { ExperienceViewModel } from '@/services/modules/experience'
import { formatPhoneNumber } from '@/utils/phone'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'ExperienceCard',

  props: {
    experience: {
      type: Object as PropType<ExperienceViewModel>,
      required: true,
    },
  },

  setup(props) {
    const formattedPhone = computed(() =>
      props.experience.addressViewModel
        ? formatPhoneNumber(props.experience.addressViewModel.phoneNumber)
        : ''
    )

    return {
      formattedPhone,
    }
  },
})
