import { AxiosInstance } from 'axios'
import { api } from '../client'
import { serviceUrl } from '../utils'
import { ToneViewModel } from './property'

const controller = serviceUrl('experience')

function createExperienceService(client: AxiosInstance) {
  return {
    get(params: ExperienceModel) {
      return client.get<ExperienceViewModel[]>(controller.action('get'), {
        params,
      })
    },
  }
}

export const experienceService = createExperienceService(api.base)

export interface ExperienceModel {
  languageId?: string
}

export interface ExperienceViewModel {
  experienceId: string
  experienceKey: number
  name: string
  website: string
  image: string
  title: string
  description: string
  addressViewModel: AddressExperienceViewModel | null
  categoryViewModel: CategoryExperienceViewModel
}

export interface AddressExperienceViewModel {
  addressId: string
  addressKey: number
  lineOne: string
  phoneNumber: string
}

export interface CategoryExperienceViewModel {
  categoryId: string
  categoryKey: number
  name: string
  title: string
  toneViewModel: ToneViewModel
  iconViewModel: IconViewModel
}

export interface IconViewModel {
  iconId: string
  iconKey: number
  name: string
}
